@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Parisienne';
    src: url('./fonts/Parisienne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
  src: url('./fonts/RubikMonoOne-Regular.ttf') format('truetype');
  font-family: 'Rubik';
  font-style: normal;
}
